import { Splide } from "@splidejs/splide";

//product carousel
class productCarousel extends HTMLElement {
  constructor() {
      super();
      this.main = this.querySelector('#main-product-carousel');
      this.thumbnail = this.querySelector('#thumbnail-product-carousel');
      this.zoom = document.querySelector('#zoom-product-carousel');

      this.zoomToggles = document.querySelectorAll('.toggle-zoom');
  }

  connectedCallback() {
    const zoomContainer = document.querySelector('.zoom-container');
   this.zoomToggles.forEach(elem => {
    elem.addEventListener('click', (event) => {
      zoomContainer.classList.toggle('max-h-0');
    })
   })

   var main = new Splide(this.main, {
      pagination: false,
      arrows    : true,
      arrowPath  : 'm2.2742.9395 20 19.9999-20 20.0001-2.1149-2.115 17.8851-17.8851-17.8851-17.885 2.1149-2.1149z',
      breakpoints : {
          1024: {
              padding: { right: '20%' }
          }
      }
    });

    if(this.zoom) {
    var zoom = new Splide(this.zoom, {
      pagination: false,
      arrows    : true,
      isNavigation : true,
      arrowPath  : 'm2.2742.9395 20 19.9999-20 20.0001-2.1149-2.115 17.8851-17.8851-17.8851-17.885 2.1149-2.1149z',
    });
  }

  var thumbnails = new Splide(this.thumbnail, {
      fixedWidth  : 90,
      fixedHeight : 118,
      height      : "100%",
      gap         : 10,
      pagination  : false,
      arrows      : false,
      isNavigation: true,
      direction   : 'ttb',
      breakpoints : {
      600: {
          fixedWidth : 60,
          fixedHeight: 44,
      },
      },
} );

  main.sync( thumbnails );
  if(this.zoom) {
  main.sync( zoom );
  }
  main.mount();
  thumbnails.mount();
  if(this.zoom) {
  zoom.mount();
  }

  var slides = document.querySelectorAll('.main__slide img');

  slides.forEach(slide => {
      slide.addEventListener('mousemove', (e) => {
          var rect = slide.getBoundingClientRect();
          var mouseX = e.clientX - rect.left;
          var mouseY = e.clientY - rect.top;
          var centerX = rect.width / 2;
          var centerY = rect.height / 2;
          var deltaX = mouseX - centerX;
          var deltaY = mouseY - centerY;
          var percentX = deltaX / centerX;
          var percentY = deltaY / centerY;
          var scale = 2
          var translateX = (percentX * 200);
          var translateY = (percentY * 200);
          slide.style.transform = 'translate(' + translateX + 'px, ' + translateY + 'px) scale(' + scale + ')';
      });

      slide.addEventListener('mouseleave', function () {
          slide.style.transform = 'translate(0, 0)';
      });
    });
  }

  initStickyScroll() {
  window.addEventListener('scroll', function() {
      if (this.stopper <= this.offsetHeight) {
          this.classList.add('relative');
          this.classList.remove('sticky');
      } else {
          this.classList.add('sticky');
          this.classList.remove('relative');
      }
  });
}

}

customElements.define('product-display-carousel', productCarousel);
